<template>
  <TaskListSelectPopover
    ref="parentRef"
    heading="Set parent"
    search-placeholder="Find a task"
    :accept-levels="[TaskLevel.TASK]"
    class="w-full"
    :board-id="boardId"
    :selected-task-id="selectedTaskId"
    v-bind="$attrs"
    @change="onSelect"
  >
    <slot />
  </TaskListSelectPopover>
</template>

<script lang="ts" setup>
import type { TaskItem } from '#task/types'
import { TaskLevel } from '#task/constant'

defineProps({
  selectedTaskId: {
    type: String as PropType<string | null>,
    default: null,
  },
  boardId: {
    type: String,
    required: true,
  },
})

const emit = defineEmits<{
  (e: 'change', task: TaskItem | null): void
}>()

const parentRef = ref<{ open: () => void, close: () => void }> ()

const onSelect = (task: TaskItem | null) => {
  emit('change', task)
}

defineExpose({ open: () => (
  parentRef.value?.open()
) })
</script>
