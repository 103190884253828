<template>
  <div
    :class="[
      'w-full h-full group/cell',
      { '!cursor-not-allowed': disabled },
    ]"
  >
    <!-- Task -->
    <TaskListModuleSelectPopover
      v-if="task.level === TaskLevel.TASK"
      ref="moduleRef"
      :selected-task-id="task.parentId"
      :board-id="task.boardId"
      :disabled="disabled"
      :accept-level="taskLevel"
      @change="updateTaskParent"
    >
      <div class="w-full h-full px-2 py-1.5 items-center flex" data-test="task-parent-item">
        <div v-if="task.parent" class="flex items-center gap-1.5">
          <TaskTypeIcon
            size="xs"
            background-class="!bg-transparent"
            icon-class="!bg-gray-900 !size-4"
            :icon="task.parent.type.icon"
            :class="{ '!cursor-not-allowed': disabled }"
          />
          <Tooltip :text="task.parent.name">
            <template #default="{ getTextRef }">
              <span :ref="getTextRef" class="text-gray-700 line-clamp-1 break-all">{{ task.parent.name }}</span>
            </template>
          </Tooltip>
        </div>
        <div v-else class="text-xs group-hover/cell:visible invisible" :class="{ '!cursor-not-allowed': disabled }">
          -
        </div>
      </div>
    </TaskListModuleSelectPopover>
    <!-- Subtask -->
    <TaskListParentSelectPopover
      v-else-if="task.level === TaskLevel.SUBTASK"
      ref="parentRef"
      :selected-task-id="task.parentId"
      :board-id="task.boardId"
      :disabled="disabled"
      :accept-level="taskLevel"
      @change="($event) => updateTaskParent($event?.id)"
    >
      <div class="w-full h-full px-2 py-1.5 items-center flex">
        <div v-if="task.parent" class="flex items-center gap-1.5">
          <TaskTypeIcon
            size="xs"
            background-class="!bg-transparent"
            icon-class="!bg-gray-900 !size-4"
            :icon="task.parent.type.icon"
          />
          <Tooltip :text="task.parent.name">
            <template #default="{ getTextRef }">
              <span :ref="getTextRef" class="text-gray-700 line-clamp-1 break-all">{{ task.parent.name }}</span>
            </template>
          </Tooltip>
        </div>
        <div v-else class="text-xs group-hover/cell:visible invisible" :class="{ '!cursor-not-allowed': disabled }">
          -
        </div>
      </div>
    </TaskListParentSelectPopover>
  </div>
</template>

<script lang="ts" setup>
import type { TaskItem } from '#task/types'
import { TaskLevel } from '#task/constant'

const props = defineProps({
  task: {
    type: Object as PropType<TaskItem>,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['set-callback'])
const toast = useToast()
const { updateTask, updateTasks } = useUpdateTask()
const { tasks } = useBoardTasksLoader()
const { checkedTasks } = useListViewSharedState()

const moduleRef = ref()
const parentRef = ref()

const taskLevel = computed(() => props.task.level === TaskLevel.TASK ? TaskLevel.MODULE : TaskLevel.TASK)

const availableTasks = computed(
  () => tasks.value.filter((task) => task.level !== TaskLevel.MODULE && checkedTasks.value.has(task.id))
)

const updateTaskParent = async (parent: string | null | undefined) => {
  if (parent) {
    if (availableTasks.value?.some((item) => item.id === props.task.id)) {
      const relativeTasks = availableTasks.value.reduce((acc: string[], item: TaskItem) => {
        if (item.level === props.task.level) {
          acc.push(item.id)
        }

        return acc
      }, [])
      return updateTasks(relativeTasks, { parent })
    }

    const data = await updateTask(props.task.id, { parent })

    if (data && 'errors' in data && data.errors.length) {
      return toast.add({
        color: 'red',
        title: 'Something went wrong. Please try again.',
      })
    }

    if (data && 'task' in data) {
      const taskParent = tasks.value.find((task) => task.id === data.task.parentId)
      toast.add({
        icon: 'i-heroicons-check-circle',
        color: 'green',
        title: `Moved "${props.task.name}" to <b>${taskParent?.name}</b>`,
      })
    }
  }
}

onMounted(() => {
  emit('set-callback', {
    open: () => {
      if (props.task.level === TaskLevel.TASK) {
        moduleRef.value?.open()
      } else {
        parentRef.value?.open()
      }
    },
  })
})
</script>
